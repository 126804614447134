@use '../abstracts/variables' as v;
@use '../abstracts/functions' as f;
@use '../abstracts/mixins' as m;
@use 'sass:map';

body{
    font-family: v.$font;
    background-color: map-get($map: v.$colors , $key: darkwhite );
    display: flex;
    justify-content: center;
    text-align: center;
}

hr {
    background-color: map-get($map: v.$colors , $key: blue );
    height: 5px;
}
