@use "../abstracts/variables" as v;
@use "../abstracts/functions" as f;
@use "../abstracts/extends.scss" as e;
@use "../abstracts/mixins" as m;
@use "sass:map";

button {
  @include m.paddingTRBL(10,25,10,25);
  @include m.marginTRBL(10,10,0,0);
}
.button {
  &--primary {
    @extend %primary-button;

    &__lotOfText {
      @extend %primary-button;

      &:hover {
        @extend %primary-buton-hover;
      }
    }

    &__disabled {
      @extend %primary-button;
      @extend %disabled;
    }

    &__cancel {
      @extend %primary-button;
      background-color: map-get($map: v.$colors, $key: red );
      color: map-get($map: v.$colors, $key: black );


      &:hover {
        @extend %primary-buton-hover;
        color: map-get($map: v.$colors, $key: red );
        background-color: map-get($map: v.$colors, $key: black );
      }
    }

    &__small {
      @extend %primary-button;
      font-size: f.pxtorem(13);
      height: 100%;

      &:hover {
        @extend %primary-buton-hover;
      }
    }
    &:hover{
      @extend %primary-buton-hover;
    }
  }

  &--secondary{
    @extend %secondary-button;

    &__lotOfText {
      @extend %secondary-button;

      &:hover {
        @extend %secondary-button-hover;
      }
    }

    &__disabled {
      @extend %secondary-button;
      @extend %disabled;
    }

    &:hover {
      @extend %secondary-button-hover;
    }
  }

  &--tertiary {
    @extend %tertiary-button;

    &__lotOfText {
      @extend %tertiary-button;

      &:hover {
        @extend %tertiary-button-hover;
      }
    }

    &__disabled {
      @extend %tertiary-button;
      @extend %disabled;
    }

    &:hover {
      @extend %tertiary-button-hover;
    }
  }
}