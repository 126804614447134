@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/extends.scss" as e;
@use "../abstracts/mediaqueries" as med;;
@use "sass:map";

.card {
  display: flex;
  background-color: map-get($map: v.$colors, $key: white );
  @include m.paddingTRBL(15,15,15,15);
  @include m.marginTRBL(30,0,0,20);
  border-radius: f.pxtorem(30);
  height: 100%;
  width: 75%;
  &__img {
    justify-content: center;
    text-align: center;
    &--img {
      height: f.pxtorem(100);
      border-radius: f.pxtorem(30);
    }
  }
  &__info {
    @include m.paddingTRBL(0,0,0,20);
    text-align: left;
  }
}