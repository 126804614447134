@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as mix;
@use "../abstracts/functions" as f;
@use "../abstracts/extends.scss" as e;
@use "../abstracts/mediaqueries" as med;;
@use "sass:map";

footer {
  display: flex;
  justify-content: space-between;
  text-align: center;

  nav {
    @include mix.paddingTRBL(50,0,0,0)
  }
  p {
    @include mix.paddingTRBL(30,0,0,0)
  }
}