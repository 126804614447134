$colors : (
    white : #FFF,
    black : #000,
    blue : #6C63FF,
    purple : #bb30ff,
    darkwhite : #F2F1FF,
    gray : #707070,
    red: #FF0000
);

$font: 'Hind Siliguri', sans-serif;
$font-weight-h1: 400;
$font-weight_bold : 800;
$font-weight_light: 300;

$small-width: 786px;
$large-width: 1024px;
