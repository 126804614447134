@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as m;
@use "../abstracts/functions" as f;
@use "../abstracts/extends.scss" as e;
@use "../abstracts/mediaqueries" as med;;
@use "sass:map";

label {
  color: map-get($map: v.$colors, $key: blue );
  font-weight: v.$font-weight_bold;
}
input {
  @include m.paddingTRBL(5,10,5,10);
  @include m.marginTRBL(10,0,0,0);
  border: none;
  border-radius: f.pxtorem(10);
  background-color: map-get($map: v.$colors, $key: white );
  
  &::placeholder {
    color: map-get($map: v.$colors, $key: blue );
  }
}

