@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as mix;
@use "../abstracts/functions" as f;
@use "../abstracts/extends.scss" as e;
@use "../abstracts/mediaqueries" as med;;
@use "sass:map";

.color-grid {
  display: flex;
  flex-wrap: wrap;
  
  .color {
    flex-basis: 10%;
    height: f.pxtorem(150);
    
  }
  @each  $key, $color in v.$colors {
    .color-#{"" + $key} {  background-color: map-get($map: v.$colors, $key: $color );}
  }
}