@use "../abstracts/variables" as v;
@use "../abstracts/mixins" as mix;
@use "../abstracts/functions" as f;
@use "../abstracts/extends.scss" as e;
@use "../abstracts/mediaqueries" as med;;
@use "sass:map";

header {
  display: flex;
  justify-content: center;
  text-align: center;
  @include mix.marginTRBL(0,0,0,0);
  @include mix.paddingTRBL(0,0,0,0);

  a {
    @include mix.marginTRBL(20,0,0,10);
  }
  button {
    @include mix.marginTRBL(10,40,0,10);
    @include mix.paddingTRBL(10,10,10,10);
    width: 30%;
  }
}
.header__map{

}